import Amplify from "aws-amplify"
import {COGNITO_ENV} from "./env"

/**
 * hack to set time timeout for amplify api call
 * ref: https://github.com/aws-amplify/amplify-js/issues/368#issuecomment-423479654
 */
import axios from "axios"
axios.interceptors.request.use(config => {
  config.timeout = 10000
})

export function configureAmplify() {
  Amplify.configure(COGNITO_ENV)

  /**
   * hack to set time timeout for amplify api call
   * ref: https://github.com/aws-amplify/amplify-js/issues/368#issuecomment-423479654
   */
  axios.interceptors.request.use(config => {
    config.timeout = 10000
  })
  // GATSBY_ENV==='development' && console.log("amplify initialized", process.env.GATSBY_STAGE)
}
